import { Model } from 'nextbone'
import { localStorage } from 'nextbone/localstorage'

@localStorage('establishment')
class Establishment extends Model {
  static defaults = {
    id: 1,
    name: '',
    cnes: '',
  }
}

export { Establishment }
