import 'basecomponent'
import { PdfViewer } from 'pdfmake-utils'

// fonts
PdfViewer.registerFont({ name: 'Roboto', fileName: 'Roboto-Regular.woff', styles: ['normal'] })
PdfViewer.registerFont({ name: 'Roboto', fileName: 'Roboto-Italic.woff', styles: ['italics'] })
PdfViewer.registerFont({ name: 'Roboto', fileName: 'Roboto-Medium.woff', styles: ['bold'] })
PdfViewer.registerFont({
  name: 'Roboto',
  fileName: 'Roboto-MediumItalic.woff',
  styles: ['bolditalics'],
})
PdfViewer.registerFont({ name: 'Calibri', fileName: 'Calibri.woff', styles: ['normal'] })
PdfViewer.registerFont({ name: 'Calibri', fileName: 'Calibri-Italic.woff', styles: ['italics'] })
PdfViewer.registerFont({ name: 'Calibri', fileName: 'Calibri-Bold.woff', styles: ['bold'] })
PdfViewer.registerFont({
  name: 'Calibri',
  fileName: 'Calibri-BoldItalic.woff',
  styles: ['bolditalics'],
})
PdfViewer.registerFont({ name: 'FA', fileName: 'FontAwesome-Regular.woff' })
PdfViewer.registerFont({ name: 'Times', fileName: 'Times-Roman', styles: ['normal'] })
PdfViewer.registerFont({ name: 'Times', fileName: 'Times-Italic', styles: ['italics'] })
PdfViewer.registerFont({ name: 'Times', fileName: 'Times-Bold', styles: ['bold'] })
PdfViewer.registerFont({ name: 'Times', fileName: 'Times-BoldItalic', styles: ['bolditalics'] })

// images
PdfViewer.registerFile({ name: 'brasao-bahia.png' })

PdfViewer.getPdfMake = () => import('pdfmake-lite')

customElements.define('pdf-viewer', PdfViewer)
