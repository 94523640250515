import { Component, html } from 'basecomponent'
import { MDBInput } from 'mdbcomponents'
import { form } from 'nextbone/form'

@form
class EstablishmentForm extends Component {
  render() {
    return html`
      <form>
        <div class="form-row">
          ${MDBInput(this.form, 'name', 'Nome estabelecimento', {
            class: 'col-md-6',
            id: 'establishment-name',
          })}
          ${MDBInput(this.form, 'cnes', 'CNES', { class: 'col-md-3', id: 'establishment-cns' })}
        </div>
      </form> 
    `
  }
}

customElements.define('establishment-form', EstablishmentForm)
