import '../main.scss'
import 'mdb-admin-pro/js/bootstrap'

// mdb
// all
// import 'imports-loader?imports=default|jquery|jQuery!mdbootstrap/js/mdb' // eslint-disable-line

import 'imports-loader?imports=default|jquery|jQuery!mdb-admin-pro/js/modules/jquery.easing' // eslint-disable-line
// import 'imports-loader?imports=default|jquery|jQuery!mdb-admin-pro/js/modules/velocity' // eslint-disable-line
// import 'imports-loader?imports=default|jquery|jQuery!mdb-admin-pro/js/modules/chart' // eslint-disable-line
import 'imports-loader?imports=default|jquery|jQuery!mdb-admin-pro/js/modules/wow' // eslint-disable-line
import 'imports-loader?imports=default|jquery|$!mdb-admin-pro/js/modules/scrolling-navbar' // eslint-disable-line
import 'imports-loader?imports=default|jquery|$!mdb-admin-pro/js/modules/waves' // eslint-disable-line
import 'imports-loader?imports=default|jquery|jQuery!mdb-admin-pro/js/modules/forms-free' // eslint-disable-line
import 'imports-loader?imports=default|jquery|jQuery!mdb-admin-pro/js/modules/enhanced-modals' // eslint-disable-line

import 'imports-loader?imports=default|jquery|jQuery!bootstrap-4-autocomplete'

import { delegate } from 'nextbone'
import $ from 'jquery'

delegate.$ = $

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('sw.js')
// }
