import { Route } from 'nextbone-routing'
import { UserView } from './user-view'
import { container } from '../common/di'

export default class extends Route {
  activate() {
    this.user = container.user
    this.establishment = container.establishment
  }

  static component = UserView

  prepareEl(el) {
    Object.assign(el, {
      user: this.user,
      establishment: this.establishment,
    })
  }
}
