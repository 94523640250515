import { html, classMap } from 'basecomponent'

export default function MDBBtnGroup(form, attr, title, items, options = {}) {
  const value = form.getValue(attr, options.model)

  const invalidText = form.touched[attr] && form.errors[attr]

  return html`
    <div class="col-md-3 btn-group-container">
      <label>${title}</label>
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        ${items.map(item => {
          let itemName, itemValue
          if (typeof item === 'object') {
            itemName = item.name
            itemValue = item.value
          } else {
            itemName = itemValue = item
          }
          return html`
            <label class="btn btn-primary btn-sm">
              <input
                type="radio"
                name=${attr}
                id=${`${options.id}-${itemValue}`}
                .value=${itemValue || null}
                autocomplete="off"
                ?checked=${itemValue === value}
              />${itemName}
            </label>
          `
        })}
      </div>
      ${invalidText &&
        html`
          <div class="invalid-feedback">${invalidText}</div>
        `}
    </div>
  `
}
