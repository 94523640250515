import { html, classMap } from 'basecomponent'

export default function MDBInput(form, attr, title, options = {}) {
  const value = form.getValue(attr, options.model)

  const invalidText = form.touched[attr] && form.errors[attr]

  return html`
    <div class=${options.class}>
      <div class="md-form">
        <input
          id=${options.id || attr}
          type=${options.type || 'text'}
          name=${attr}
          class="form-control ${classMap({ 'is-invalid': !!invalidText })}"
          .value=${value || null}
        />
        <label class=${classMap({ active: !!value })} for=${options.id || attr}>${title}</label>
        ${invalidText &&
          html`
            <div class="invalid-feedback">${invalidText}</div>
          `}
      </div>
    </div>
  `
}
