import { Route } from 'nextbone-routing'
import { container } from '../common/di'

export default class extends Route {
  static childRoutes = {
    forms() {
      return import('../forms/route')
    },
  }

  activate() {
    // initialize user
    const { user, establishment } = container
    user.fetch().catch(() => {
      user.save()
    })
    establishment.fetch().catch(() => {
      establishment.save()
    })
  }
}
