import './setup/main'
import './setup/services'
import './setup/components'
import AppRoute from './app/route'
import UserRoute from './user/route'
import { Router, bindRouterLinks } from 'nextbone-routing'
import { Radio } from 'nextbone-radio'

// create the router
let router = new Router({ log: true, logError: true, outlet: '#main-view' })

// define the routes
router.map(function (route) {
  route('application', { path: '/', class: AppRoute }, function () {
    route('forms', { path: '' }, function () {
      route('forms.sibutramin', { path: 'sibutramin' })
      route('forms.anfetamin', { path: 'anfetamin' })
      route('forms.dyslipidemia', { path: 'dyslipidemia' })
      route('forms.dapaglifozin', { path: 'dapaglifozin' })
      route('forms.insulinanalog', { path: 'insulinanalog' })
      route('forms.gnrh-analog', { path: 'gnrh-analog' })
      route('forms.apac', { path: 'apac' })
    })
    route('user', { path: 'user', class: UserRoute })
  })
})

// start listening to URL changes
router.listen()

bindRouterLinks(document.getElementById('main-navbar'))

// listen to events using Radio
Radio.channel('router').on('transition:error', function (transition, err) {
  console.log('error', err)
})
