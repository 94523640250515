import { Model } from 'nextbone'
import { localStorage } from 'nextbone/localstorage'
import { withValidation } from 'nextbone/validation'

@withValidation
@localStorage('user')
class User extends Model {
  static validation = {
    name: {
      required: true,
    },
    crm: {
      required: true,
    },
    phone: {
      required: true,
    },
  }

  static defaults = {
    id: 1,
    name: '',
    crm: '',
  }
}

export { User }
