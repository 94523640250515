import { Component, html } from 'basecomponent'
import { event } from 'nextbone'
import { MDBInput } from 'mdbcomponents'
import { form } from 'nextbone/form'

@form
class UserForm extends Component {
  render() {
    return html`
      <form>
        <div class="form-row">
          ${MDBInput(this.form, 'name', 'Nome médico', {
            class: 'col-md-6',
            id: 'user-name',
          })}
        </div>
        <div class="form-row">
          ${MDBInput(this.form, 'crm', 'CRM', { class: 'col-md-3', id: 'user-crm' })}
          ${MDBInput(this.form, 'cns', 'CNS (Cartão SUS)', {
            class: 'col-md-3',
            id: 'user-cns',
          })}
        </div>
        <div class="form-row">
          ${MDBInput(this.form, 'phone', 'Telefone', {
            class: 'col-md-3',
            id: 'user-phone',
          })}
        </div>
      </form>
    `
  }
}

customElements.define('user-form', UserForm)
