import { service, decorator } from 'di'
import { MODAL_SERVICE } from 'indentifiers'
import { User } from 'entities/user'
import { BootstrapModals } from 'nextbone-modals'
import { Establishment } from 'entities/establishment.js'

// configure services here
// more info at https://github.com/young-steveo/bottlejs

service(MODAL_SERVICE, BootstrapModals)
decorator(MODAL_SERVICE, function(modal) {
  modal.setup({
    container: '.application__overlay',
  })
  return modal
})

service('user', User)
service('establishment', Establishment)
