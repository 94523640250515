import { Component, html, classMap } from 'basecomponent'
import { event, view } from 'nextbone'

import './user-form'
import './establishment-form'
import { property } from 'nextbone-routing'
import { query } from 'lit-element'

@view
class UserView extends Component {
  user

  establishment

  @property({ attribute: false })
  isFormValid = false

  @query('user-form')
  userForm

  @event('click', '#user-save')
  saveClick() {
    this.user.save()
    this.establishment.save()
  }

  connectedCallback() {
    super.connectedCallback()
    this.listenTo(this.user, 'change', () => {
      this.isFormValid = this.userForm.form.isValid({
        update: false,
        attributes: ['name', 'crm', 'phone'],
      })
      this.requestUpdate()
    })
  }

  render() {
    return html`
      <div class="row">
        <div class="col">
          <h3 class="h3-responsive">Configurações</h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5>Dados do usuário</h5>
              <user-form .model=${this.user}></user-form>

              <h5>Estabelecimento de saúde</h5>
              <establishment-form .model=${this.establishment}></establishment-form>
              <button
                type="button"
                id="user-save"
                class="btn btn-primary ${classMap({
                  disabled: !this.isFormValid,
                })}"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    `
  }
}

customElements.define('user-view', UserView)

export { UserView }
